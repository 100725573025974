// components/AboutSection.jsx
import React, { useState } from 'react';
import ewnImg from '../assets/ewn.png';
import { TwitterTweetEmbed } from 'react-twitter-embed';

const AboutSection = () => {
  const [copied, setCopied] = useState(false);
  const contractAddress = '0xAfFf6d4fF7b3fFf6fFfFfFfFfFfFfFfFfFfFfF';
  const tweetIds = [
    '1602278477234728960',
    '1783858054821450128',
  ];

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <section id="about" className="about-section">
      <h2>END WOKENESS</h2>
      <div className="about-image">
        <img src={ewnImg} alt="About" />
      </div>

      <div className="media-container">
        <div className="tweet-grid">
          {tweetIds.map((tweetId) => (
            <div key={tweetId} className="tweet-container">
              <TwitterTweetEmbed
                tweetId={tweetId}
                options={{
                  theme: 'dark',
                  width: '100%',
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div id="tokenomics" className="info-flexbox">
        <div className="contract-address">
          <h3>CONTRACT ADDRESS</h3>
          <p onClick={copyToClipboard}>
            {contractAddress}
          </p>
        </div>
        <div className="info-item">
          <h3>SUPPLY</h3>
          <p>1 BILLION</p>
        </div>
        <div className="info-item">
          <h3>TAXES</h3>
          <p>0/0</p>
        </div>
        <div className="info-item">
          <h3>LIQUIDITY</h3>
          <p>BURNED</p>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;