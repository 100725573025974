// components/LandingSection.js
import React, { useState, useEffect } from 'react';
import heroImg from '../assets/WMDBase.png';
import heroImgMobile from '../assets/WMDBasePortrait.png';
import panelTxt from '../assets/WMDText.png';

const LandingSection = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const videoIds = [
    'HKpsnjJOX9I',
    'pX9U-Win078',
    '9TKnrRIkPik',
    'zwDglVlLQe0',
    'wDYNVH0U3cs',
    'fuSPU_56iiQ',
    'Fed5RzXyU20',
    'rrZxNh0uck4',
    'BY1H1rZL53I'
  ];

  return (
    <section className="landing-section">
      <div className="hero-container">
        <div className="hero-image">
          {isMobile ? (
            <img src={heroImgMobile} alt="Hero Mobile" />
          ) : (
            <img src={heroImg} alt="Hero Desktop" />
          )}
        </div>
        <div className="panel-text">
          <img src={panelTxt} alt="Panel Text" />
        </div>
      </div>
      <div className="video-grid">
        {videoIds.map((videoId, index) => (
          <div key={index} className="video-item">
            <iframe
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&loop=1&playlist=${videoId}`}
              title={`YouTube video player ${index + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LandingSection;