// App.js
import React from 'react';
import './styles.css';
import Navbar from './components/Navbar';
import LandingSection from './components/LandingSection';
import AboutSection from './components/AboutSection';

function App() {
  return (
    <div className="App">
      <Navbar />
      <LandingSection />
      <AboutSection />
    </div>
  );
}

export default App;