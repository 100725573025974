// components/Navbar.js
import React, { useState } from 'react';
import logo from '../assets/ewn.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setIsOpen(false); // Close the menu after clicking
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
        <a href="#home" onClick={() => scrollToSection('home')}>HOME</a>
        <a href="#about" onClick={() => scrollToSection('about')}>ABOUT</a>
        <a href="#tokenomics" onClick={() => scrollToSection('tokenomics')}>TOKENOMICS</a>
        <a href="https://www.dexscreener.com/" target="_blank" rel="noreferrer">CHART</a>
        <a href="https://t.co/LM1iyeumLl" target="_blank" rel="noreferrer">COMMUNITY</a>
        <a href="https://x.com/WokeMindSOL" target="_blank" rel="noreferrer">TWITTER</a>
      </div>
      <div className={`navbar-buy ${isOpen ? 'active' : ''}`}>
        <button onClick={() => window.open("https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=")}>BUY NOW</button>
      </div>
      <div className="navbar-hamburger" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
};

export default Navbar;